/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { IconButton } from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {color} from "chart.js/helpers";
import ModalComponent from "./components/ModalComponent";

// Data
// import dataTableData from "layouts/pages/souscriptions/data/dataTableData";

function DataTables() {

  const [open, setOpen] = useState(false);
  const [data, setData] = useState('');

  const [dataTableData, setDataTableData] = useState({
    columns: [
      { Header: "name", accessor: "name" },
      { Header: "date", accessor: "date" },
    ],
    rows: []
  });

  const handleOpen = (data) => {
    setData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = () => {
    fetch('http://localhost:8001/api/subscriptions')
      .then(response => response.json())
      .then(jsonData => {

        // map the JSON data to objects with name and date properties
        const formattedData = jsonData.map((item) => {
          const d = item.data;
          const date = new Date(item.created_at);
          const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
          const month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
          return {
            date: day + "/" + month + "/" + date.getFullYear(),
            name: d.firstName + " " + d.lastName,
            choosenModels: d.choosenModels,
            
            emploi: d.jobPosition,
            actions: "<b><button>Details</button></b>"
          };
        });
        console.log(formattedData);
        setDataTableData({
          columns: [
            { Header: "Nom", accessor: "name" },
            { Header: "inscrit le", accessor: "date" },
            { Header: "Emploi", accessor: "emploi" },
            { Header: "Modèles choisis", accessor: "choosenModels" },
            
            {
              Header: "Actions", accessor: "actions", Cell: ({ value }) => {
                return <MDBox>
                  {/* <IconButton size="medium" 
                    onClick={()=> handleOpen( JSON.stringify(formattedData) 
                  )}>
                    <FolderIcon style={{color : "#78909C"}}/>
                  </IconButton> */}
                            <IconButton size="medium" >
                    <FolderIcon style={{color : "#78909C"}}/>
                  </IconButton>
                  <IconButton  size="medium">
                    <VerifiedUserIcon style={{color : "#66BB6A"}}/>
                  </IconButton>
                </MDBox>;
              },
            }
          ],

          rows: formattedData
        });
      })
      .catch(error => console.error(error));
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Liste des souscripteurs
            </MDTypography>
          </MDBox>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </MDBox>
      <ModalComponent open={open} handleClose={handleClose} data={data} />
      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;
