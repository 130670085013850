import model_1_1 from "assets/images/computers/model_1/1.avif";
import model_1_2 from "assets/images/computers/model_1/2.avif";
import model_1_3 from "assets/images/computers/model_1/3.avif";
import model_1_4 from "assets/images/computers/model_1/4.avif";
import model_1_5 from "assets/images/computers/model_1/5.avif";
import model_1_6 from "assets/images/computers/model_1/6.avif";

import model_2_1 from "assets/images/computers/model_2/1.png";
import model_2_2 from "assets/images/computers/model_2/2.png";
import model_2_3 from "assets/images/computers/model_2/3.png";
import model_2_4 from "assets/images/computers/model_2/4.png";

import model_4_1 from "assets/images/computers/model_4/1.png";
import model_4_2 from "assets/images/computers/model_4/2.png";
import model_4_3 from "assets/images/computers/model_4/3.png";
import model_4_4 from "assets/images/computers/model_4/4.png";

import model_3_1 from "assets/images/computers/model_3/1.webp";
import model_3_2 from "assets/images/computers/model_3/2.png";
import model_3_3 from "assets/images/computers/model_3/3.png";
import model_3_4 from "assets/images/computers/model_3/4.png";

import lenovo_g7_1 from "assets/images/computers/lenovo_g7/1.jpg";
import lenovo_g7_2 from "assets/images/computers/lenovo_g7/2.png";
import lenovo_g7_3 from "assets/images/computers/lenovo_g7/3.png";
import lenovo_g7_4 from "assets/images/computers/lenovo_g7/4.png";

const computers = {
    modele_HP_250_G10_I3: {
        title: "HP 250 G10 - I3",
        image: model_4_1,
        images: [model_4_1, model_4_2, model_4_3, model_4_4],
        short_desc:
            'HP 250 G10 - 15,6" - Intel Core i3-1340U - 8 Go RAM',
        long_desc: `<table border="1" class="specifications-table">  
                    <tr>    
                        <th>Caractéristique</th>    
                        <th>Description</th>  
                    </tr>  
                    <tr>    
                        <td>Modèle</td>    
                        <td>HP 250 G10 i3</td>  
                    </tr>  
                    <tr>    
                        <td>Processeur</td>    
                        <td>Processeur: Fréquence de base Efficient-core de 0,9 GHz et jusqu'à 3,3 GHz Efficient-core avec la technologie Intel Turbo Boost
                         10 Mo de cache, 6 cœurs</td>  
                    </tr>  
                    <tr>    
                        <td>Mémoire RAM</td>    
                        <td>8 Go de mémoire RAM DDR4-3200 MHz (1 x 8 Go)</td>  
                    </tr>  
                    <tr>    
                        <td>Écran</td>    
                        <td>
                            Écran Full HD de 39,6 cm (15,6 pouces) de diagonale (1920 x 1080), IPS, micro-bords, antireflet, 250 nits, 45 % NTSC
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Disque Dur</td>    
                        <td>
                            Disque dur électronique PCIe® NVMe 256 Go
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Carte graphique</td>    
                        <td>Carte graphique Intel® UHD</td>  
                    </tr>  
                    <tr>    
                        <td>Système d'exploitation</td>   
                         <td>Windows 11 Home</td>  
                    </tr>
                </table>`,
        value: "modele_HP_250_G10_I3",
        monthly: "16 600",
        apport: "40 000",
    },

    modele_HP_250_G10_i5: {
        title: "HP 250 G10 - i5",
        image: model_4_1,
        images: [model_4_1, model_4_2, model_4_3, model_4_4],
        short_desc:
            'HP 250 G10 - 15,6" - Intel Core i5-1340U - 8 Go RAM',
        long_desc: `<table border="1" class="specifications-table">  
                    <tr>    
                        <th>Caractéristique</th>    
                        <th>Description</th>  
                    </tr>  
                    <tr>    
                        <td>Modèle</td>    
                        <td>HP 250 G10 i5</td>  
                    </tr>  
                    <tr>    
                        <td>Processeur</td>    
                        <td> 
                            Fréquence de base Efficient-core de 0,9 GHz et jusqu'à 3,4 GHz Efficient-core avec la technologie Intel Turbo Boost
                            12 Mo de cache, 10 cœurs
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Mémoire RAM</td>    
                        <td>8 Go de mémoire RAM DDR4-3200 MHz (1 x 8 Go)</td>  
                    </tr>  
                    <tr>    
                        <td>Écran</td>    
                        <td>
                            Écran Full HD de 39,6 cm (15,6 pouces) de diagonale (1920 x 1080), IPS, micro-bords, antireflet, 250 nits, 45 % NTSC
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Disque Dur</td>    
                        <td>
                            Disque dur électronique PCIe® NVMe 256 Go
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Carte graphique</td>    
                        <td>Carte graphique Intel® UHD</td>  
                    </tr>  
                    <tr>    
                        <td>Système d'exploitation</td>   
                         <td>Windows 11 Home</td>  
                    </tr>
                </table>`,
        value: "modele_HP_250_G10_i5",
        monthly: "17 700",
        apport: "42 000",
    },

    modele_HP_440_G11_16: {
        title: "HP ProBook 440 G11 - U5",
        image: model_3_1,
        images: [model_3_1, model_2_2, model_2_3, model_2_4],
        short_desc:
            'HP ProBook 440 G11 - 14" - Intel® Core Ultra 5 125U - 16 Go RAM',
        long_desc: `<table border="1" class="specifications-table">  
                    <tr>    
                        <th>Caractéristique</th>    
                        <th>Description</th>  
                    </tr>  
                    <tr>    
                        <td>Modèle</td>    
                        <td>HP ProBook 440 G11 - U5</td>  
                    </tr>  
                    <tr>    
                        <td>Processeur</td>    
                        <td> 
                            Intel® Core Ultra 5 125U (jusqu’à 4,3 GHz 
                            avec la technologie Intel® Turbo Boost, 12 Mo de 
                            mémoire cache L3, 12 cœurs, 14threads) 1 2
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Mémoire RAM</td>    
                        <td>16 Go DDR5-5600 MT/s (1 x 16 Go)</td>  
                    </tr>  
                    <tr>    
                        <td>Écran</td>    
                        <td>
                            Écran Full HD de 35,6 cm (14 pouces) (1920 x 1200), IPS, 
                            micro-bords, antireflet, 300 nits, 45 % NTSC
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Disque Dur</td>    
                        <td>
                            Disque SSD NVMe  PCIe® 512 Go
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Carte graphique</td>    
                        <td>Carte graphique Intel® UHD </td>  
                    </tr>  
                    <tr>    
                        <td>Système d'exploitation</td>   
                         <td>Windows 11 Home</td>  
                    </tr>
                </table>`,
        value: "modele_HP_440_G11_16",
        monthly: "23 000",
        apport: "55 000",
    },

    modele_HP_460_G11_U5: {
        title: "HP ProBook 460 G11 - U5",
        image: model_2_1,
        images: [model_2_1, model_2_2, model_2_3, model_2_4],
        short_desc:
            'HP ProBook 460 G11 - 16" - Intel® Core Ultra 5 125U - 16 Go RAM',
        long_desc: `<table border="1" class="specifications-table">  
                    <tr>    
                        <th>Caractéristique</th>    
                        <th>Description</th>  
                    </tr>  
                    <tr>    
                        <td>Modèle</td>    
                        <td>HP ProBook 460 G11 - U5</td>  
                    </tr>  
                    <tr>    
                        <td>Processeur</td>    
                        <td> 
                            Processeur Intel® Core Ultra 5 125U (jusqu’à 4,3 GHz 
                            avec la technologie Intel® Turbo Boost, 12 Mo de 
                            mémoire cache L3, 12 cœurs, 14threads)
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Mémoire RAM</td>    
                        <td>16 Go DDR5-5600 MT/s (1 x 16 Go)</td>  
                    </tr>  
                    <tr>    
                        <td>Écran</td>    
                        <td>
                            Ecran Full HD 16 pouces, WUXGA (1920 x 1200), IPS, 
                            antireflets, 300 nits, 45% NTS
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Disque Dur</td>    
                        <td>
                            Disque SSD NVMe  PCIe® 512 Go
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Carte graphique</td>    
                        <td>Carte graphique Intel® UHD </td>  
                    </tr>  
                    <tr>    
                        <td>Système d'exploitation</td>   
                         <td>Windows 11 Home</td>  
                    </tr>
                </table>`,
        value: "modele_HP_460_G11_U5",
        monthly: "25 000",
        apport: "60 000",
    },

    modele_Lenovo_V15_G4_IRU_i3: {
        title: "Lenovo V15 G4 IRU - i3",
        image: model_1_1,
        images: [model_1_1, model_1_2, model_1_3, model_1_4, model_1_5, model_1_6],
        short_desc:
            'Lenovo V15 G4 IRU - 15,6" - Intel® Core i3-1315U, 8 Go RAM',
        long_desc: `<table border="1" class="specifications-table">  
                    <tr>    
                        <th>Caractéristique</th>    
                        <th>Description</th>  
                    </tr>  
                    <tr>    
                        <td>Modèle</td>    
                        <td>Lenovo V15 G4 IRU - i3</td>  
                    </tr>  
                    <tr>    
                        <td>Processeur</td>    
                        <td>Intel® Core i3-1315U, 6C (2P + 4E) / 8T, P-core 1.2 / 
                            4.5GHz, E-core 0.9 / 3.3GHz, 10MB
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Mémoire RAM</td>    
                        <td>8 Go de mémoire RAM DDR4-3200 MHz (1 x 8 Go)</td>  
                    </tr>  
                    <tr>    
                        <td>Écran</td>    
                        <td>
                        Écran Full HD de 39,6 cm (15,6 pouces) de diagonale (1920 x 1080), Anti-éblouissement, technologie Low Blue Light, 250 nits, 45 % NTSC
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Disque dur</td>    
                        <td>Disque dur électronique 256 Go SSD</td>  
                    </tr>  
                    <tr>    
                        <td>Carte graphique</td>    
                        <td>
                        Integrated Intel® UHD Graphics
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Système d'exploitation</td>   
                         <td>Windows 11 Home</td>  
                    </tr>
                </table>`,
        value: "modele_Lenovo_V15_G4_IRU_i3",
        monthly: "18 000",
        apport: "43 000",
    },

    modele_LENOVO_V15_GEN4_256: {
        title: "Lenovo V15 Gen 4 - i5 / 256 gb",
        image: model_1_1,
        images: [model_1_1, model_1_2, model_1_3, model_1_4, model_1_5, model_1_6],
        short_desc:
            'Lenovo V15 G4 IRU - 15,6" - Intel® Core i5-1335U, 8 Go RAM - 256 gb SSD',
        long_desc: `<table border="1" class="specifications-table">  
                    <tr>    
                        <th>Caractéristique</th>    
                        <th>Description</th>  
                    </tr>  
                    <tr>    
                        <td>Modèle</td>    
                        <td>Lenovo V15 G4 IRU i5</td>  
                    </tr>  
                    <tr>    
                        <td>Processeur</td>    
                        <td>Intel® Core i5-1335U, 10C (2P + 8E) / 12T, P-core 1.3 / 
                            4.6GHz, E-core 0.9 / 3.4GHz, 12MB
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Mémoire RAM</td>    
                        <td>8 Go de mémoire RAM DDR4-3200 MHz (1 x 8 Go)</td>  
                    </tr>  
                    <tr>    
                        <td>Écran</td>    
                        <td>Écran Full HD de 39,6 cm (15,6 pouces) de diagonale 
                            (1920 x 1080), Anti-éblouissement, technologie Low 
                            Blue Light, 250 nits, 45 % NTSC
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Disque dur</td>    
                        <td>Disque dur électronique 256 Go SSD</td>  
                    </tr>  
                    <tr>    
                        <td>Carte graphique</td>    
                        <td>Integrated Intel® Iris® Xe Graphics Functions as UHD 
                            Graphics
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Système d'exploitation</td>   
                         <td>Windows 11 Home</td>  
                    </tr>
                </table>`,
        value: "modele_LENOVO_V15_GEN4_256",
        monthly: "22 000",
        apport: "52 000",
    },

    modele_LENOVO_V15_GEN4_512: {
        title: "Lenovo V15 Gen 4 - i5 / 512 gb",
        image: model_1_1,
        images: [model_1_1, model_1_2, model_1_3, model_1_4, model_1_5, model_1_6],
        short_desc:
            'Lenovo V15 G4 IRU - 15,6" - Intel® Core i5-1335U, 8 Go RAM - 512 gb SSD',
        long_desc: `<table border="1" class="specifications-table">  
                    <tr>    
                        <th>Caractéristique</th>    
                        <th>Description</th>  
                    </tr>  
                    <tr>    
                        <td>Modèle</td>    
                        <td>Lenovo V15 G4 IRU i5</td>  
                    </tr>  
                    <tr>    
                        <td>Processeur</td>    
                        <td>Intel® Core i5-1335U, 10C (2P + 8E) / 12T, P-core 1.3 / 
                            4.6GHz, E-core 0.9 / 3.4GHz, 12MB
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Mémoire RAM</td>    
                        <td>8 Go de mémoire RAM DDR4-3200 MHz (1 x 8 Go)</td>  
                    </tr>  
                    <tr>    
                        <td>Écran</td>    
                        <td>Écran Full HD de 39,6 cm (15,6 pouces) de diagonale 
                            (1920 x 1080), Anti-éblouissement, technologie Low 
                            Blue Light, 250 nits, 45 % NTSC
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Disque dur</td>    
                        <td>Disque dur électronique 512 Go SSD</td>  
                    </tr>  
                    <tr>    
                        <td>Carte graphique</td>    
                        <td>Integrated Intel® Iris® Xe Graphics Functions as UHD 
                            Graphics
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Système d'exploitation</td>   
                         <td>Windows 11 Home</td>  
                    </tr>
                </table>`,
        value: "modele_LENOVO_V15_GEN4_512",
        monthly: "23 500",
        apport: "56 000",
    },

    modele_LENOVO_THINKBOOK_14_GEN_7: {
        title: "Lenovo ThinkBook 14 G7",
        image: lenovo_g7_1,
        images: [lenovo_g7_1, lenovo_g7_2, lenovo_g7_3, lenovo_g7_4],
        short_desc:
            'Lenovo ThinkBook 14 G7 - 14" - Intel Core ULT7 155H 1.4G 16C 22T, 8GB RAM',
        long_desc: `<table border="1" class="specifications-table">  
                    <tr>    
                        <th>Caractéristique</th>    
                        <th>Description</th>  
                    </tr>  
                    <tr>    
                        <td>Modèle</td>    
                        <td>Lenovo ThinkBook 14 G7</td>  
                    </tr>  
                    <tr>    
                        <td>Processeur</td>    
                        <td>
                            Intel Core ULT7 155H 1.4G 16C 22T
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Mémoire RAM</td>    
                        <td>8GB DDR5 5600 SoDIMM</td>  
                    </tr>  
                    <tr>    
                        <td>Écran</td>    
                        <td>Écran 14" WUXGA (1 920 x 1 200), IPS, anti-éblouissement, non tactile, 45 % NTSC, 300 nits, 60 Hz, panneau étroit
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Disque dur</td>    
                        <td>512 Go SSD M.2 2242 PCIe Gen4 TLC</td>  
                    </tr>  
                    <tr>    
                        <td>Carte graphique</td>    
                        <td>
                            Integrated Intel® Iris® Xe Graphics Functions as UHD Graphics
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Système d'exploitation</td>   
                         <td>Windows 11 Home</td>  
                    </tr>
                </table>`,
        value: "modele_LENOVO_THINKBOOK_14_GEN_7",
        monthly: "29 000",
        apport: "68 000",
    },

    modele_Lenovo_ThinkBook_16_G7: {
        title: "Lenovo ThinkBook 16 G7 ",
        image: lenovo_g7_1,
        images: [lenovo_g7_1, lenovo_g7_2, lenovo_g7_3, lenovo_g7_4],
        short_desc:
            'Lenovo ThinkBook 16 G7 - 16" - Intel Core ULT5 125U 1.3G 12C 14T, 8GB RAM',
        long_desc: `<table border="1" class="specifications-table">  
                    <tr>    
                        <th>Caractéristique</th>    
                        <th>Description</th>  
                    </tr>  
                    <tr>    
                        <td>Modèle</td>    
                        <td>Lenovo ThinkBook 14 G7</td>  
                    </tr>  
                    <tr>    
                        <td>Processeur</td>    
                        <td>
                            Intel Core ULT5 125U 1.3G 12C 14T
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Mémoire RAM</td>    
                        <td>8GB DDR5 5600 SODIMM</td>  
                    </tr>  
                    <tr>    
                        <td>Écran</td>    
                        <td>
                           Écran Full HD de 16"WUXGA (1920x1200) IPS Antireflets, non tactile, 16:10 - 300nits, 45% NTSC, 60Hz
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Disque dur</td>    
                        <td>512GB SSD M.2 2242 Gen 4 TLC</td>  
                    </tr>  
                    <tr>    
                        <td>Carte graphique</td>    
                        <td>
                           Integrated Intel® UHD Graphics
                        </td>  
                    </tr>  
                    <tr>    
                        <td>Système d'exploitation</td>   
                         <td>Windows 11 Home</td>  
                    </tr>
                </table>`,
        value: "modele_LENOVO_THINKBOOK_16_GEN_7",
        monthly: "29 000",
        apport: "68 000",
    },
};
export default computers;
