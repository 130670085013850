/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import boxShadow from "assets/theme/functions/boxShadow";
import { InputNumber } from 'primereact/inputnumber';
        

function HouseCard({
  id,
  quantity,
  handleQuantityChange,
  image,
  label,
  title,
  description,
  apport,
  action,
  authors,
  value,
  currentValue,
  onValueChanged,
  hideButton,
  monthly,
  isRecap,
}) {
  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <MDAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  function onButtonClicked() {
    onValueChanged(value);
  }

  return (
    <Grid item xs={12} md={6} lg={10} style={{ margin: "auto" }}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "visible",
        }}
      >
        <MDBox
          position="relative"
          width="100%"
          borderRadius="xl"
          style={{ boxShadow: "none !important" }}
          mb={2}
        >
          <CardMedia
            onClick={onButtonClicked}
            src={image}
            component="img"
            title={title}
            sx={{
              height: "200px",
              margin: "auto",
              boxShadow: "none",
              objectFit: "cover",
              objectPosition: "center",
            }}
            style={{ cursor: "pointer" }}
          />
        </MDBox>
        <MDBox mt={1} mx={0.5}>
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
          >
            {label}
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography
              to={action.route}
              variant="h5"
              textTransform="capitalize"
            >
              {title}{" "}
              <span style={{ color: "#1F34E4" }}>
                {quantity > 0 ? `( ` + quantity + ` unités )` : ""}
              </span>
            </MDTypography>
          </MDBox>
          <MDBox mb={3} lineHeight={0} height={"30px !important"}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox mb={3} lineHeight={0}>
            <MDTypography
              variant="button"
              color="dark"
              style={{
                height: "50px !important",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              {isRecap && quantity > 1 ? quantity + " x " : ""} {monthly} F CFA
              / mois pendant 24 mois <br />{" "}
              <span style={{ fontSize: "80%" }}>
                (Apport initial de {apport} F CFA)
              </span>
            </MDTypography>
          </MDBox>
          {isRecap ? (
            ""
          ) : (
            <MDBox mb={3}>
              <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                
                  <div style={{ paddingRight: "10px" }}>
                    <span>Choisir quantité : </span>
                  </div>
                  <InputNumber
                    value={quantity}
                    onChange={(e) => {
                      const value = Math.max(0, Math.min(e.value, 4));
                      // if(e.value !== value){
                      //   e.value = value;
                      // }
                      handleQuantityChange(value);
                    }}
                    showButtons
                    buttonLayout="horizontal"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    decrementButtonClassName="p-button-secondary p-button-sm p-button-rounded" 
                    incrementButtonClassName="p-button-secondary p-button-sm p-button-rounded"
                    max={4}
                    min={0}
                    inputStyle={{ width: "50px", textAlign: "center",  }}
                    
                  />
                 
              </div>
            </MDBox>
          )}
          <MDBox display="flex" justifyContent="center" alignItems="center">
            {hideButton ? (
              ""
            ) : (
              <MDButton
                variant={"gradient"}
                size="small"
                color={action.color}
                onClick={onButtonClicked}
              >
                {"Détails du modèle"}
              </MDButton>
            )}
            <MDBox display="flex">{renderAuthors}</MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}

// Setting default values for the props of DefaultProjectCard
HouseCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
HouseCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default HouseCard;
