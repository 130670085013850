/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";

// NewUser page components
import FormField from "layouts/pages/preinscription/components/FormField";
import { useState } from "react";
import MDDatePicker from "../../../../../components/MDDatePicker";
import MDButton from "../../../../../components/MDButton";
import Icon from "@mui/material/Icon";

function SituationSocioPro({ formData, isRecap, gotoStep, step }) {

    const { formField, values, errors, formFieldGrid, orderedFieldsBox } = formData;

    const {
       
        hasSalary,
        
        hasExtraEarnings, hasAval,
        
        
        
        
        hasLoan, loanAmount, loanDuration, loanMonthlyPayment,
        loanRemaining, hasLoanOther, loanOther, 
    
            } = formField;

    // Emploi

    // Lieu de travail


    const orderedFieldsHasLoan = {
        "title": "Engagement bancaire et mode de règlement",
        "items": [
            hasLoan
        ]
    };

    const orderedFieldsBankEngagement = {
        "title": "",
        "items": [
            loanAmount, loanDuration, loanMonthlyPayment,
            loanRemaining,
        ]
    };







    const [isPublicWorkerState, setIsPublicWorkerState] = useState(true);

    const [isLiberalInformelState, setIsLiberalInformelState] = useState(false);
    
    const [hasSalaryState, setHasSalaryState] = useState(true);
    

    const [hasLoanState, setHasLoanState] = useState(false);
    const [hasLoanOtherState, setHasLoanOtherState] = useState(false);
    
    const [hasExtraEarningsState, setHasExtraEarningsState] = useState(true);
    


    const [defaultState, setDefaultState] = useState(true);

    function handleSelectedOption(itemValue, itemName) {

        if (itemName === hasSalary.name) {
            setHasSalaryState(itemValue === "Oui");
        }

        else if (itemName === hasExtraEarnings.name) {
            setHasExtraEarningsState(itemValue === "Oui");
        }

        else if (itemName === hasLoan.name) {
            setHasLoanState(itemValue === "Oui");
        }

        
        else if (itemName === hasLoanOther.name) {
            setHasLoanOtherState(itemValue === "Oui");
        }

        else {
            setDefaultState(!defaultState);
        }

    }

    function orderedFieldsJobDomain() {

        var key = "orderedFieldsJobDomain";

        return (
            <MDBox mt={4} key={key}>
                <MDBox lineHeight={0} mb={3}>
                    <MDTypography variant="h5">Emploi</MDTypography>
                </MDBox>

                <MDBox mt={1.625}>
                    <Grid container spacing={3}>
                            {[
                                // formFieldGrid(formField.matricule.name, formField.matricule, values, errors, handleSelectedOption, { isRecap }),
                                formFieldGrid(formField.matricule.name, formField.matricule, values, errors, handleSelectedOption, { isRecap }),
                                formFieldGrid(formField.jobPosition.name, formField.jobPosition, values, errors, handleSelectedOption, { isRecap }),
                                
                                formFieldGrid(formField.salary.name, formField.salary, values, errors, handleSelectedOption, { isRecap }),
                                <Grid item xs={12} sm={6} key={key}></Grid>,

                                formFieldGrid(formField.ministry.name, formField.ministry, values, errors, handleSelectedOption, { isRecap }),
                                formFieldGrid(formField.mutual.name, formField.mutual, values, errors, handleSelectedOption, { isRecap }),

                            ]}
                         
                    </Grid>
                </MDBox>

            </MDBox>);
    }


    function orderedFieldsLoanOther() {

        var key = "orderedFieldsLoanOther";

        return (
            <MDBox mt={4} key={key}>
                <MDBox lineHeight={0} mb={3}>
                    <MDTypography variant="h5">Autres engagements financiers</MDTypography>
                </MDBox>

                <MDBox mt={1.625}>
                    <Grid container spacing={3}>
                        {formFieldGrid(hasLoanOther.name, hasLoanOther, values, errors, handleSelectedOption, { isRecap })}

                        {hasLoanOtherState ? formFieldGrid(loanOther.name, loanOther, values, errors, handleSelectedOption, { isRecap }) : ""}

                    </Grid>
                </MDBox>

            </MDBox>);
    }

    return (
        <MDBox>

            <MDBox>
                <MDTypography variant="h4" textAlign={"center"} mb={3} mt={1}>
                    SITUATION SOCIO-PROFESSIONNELLE
                    {isRecap ? <MDButton variant="outlined" color="dark" iconOnly style={{ marginLeft: "8px" }} onClick={() => gotoStep(step)}>
                        <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                    </MDButton> : ""
                    }
                </MDTypography>
            </MDBox>

            {orderedFieldsJobDomain()}

            {orderedFieldsBox("orderedFieldsHasLoan", orderedFieldsHasLoan, values, errors, handleSelectedOption, { isRecap })}
            {hasLoanState ? orderedFieldsBox("orderedFieldsBankEngagement", orderedFieldsBankEngagement, values, errors, handleSelectedOption, { isRecap }) : ""}

            {orderedFieldsLoanOther()}
           
        </MDBox>
    );
}

// typechecking props for Identification
SituationSocioPro.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SituationSocioPro;
