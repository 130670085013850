import React from 'react';
import { Container, Box, Card, CardContent, Typography, Button } from '@mui/material';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessPageMaterial = () => {
    return (
        <PageLayout>
            <Container maxWidth="sm">
                <Box mt={10}>
                    <Card>
                        <CardContent>
                            <Box textAlign="center" pt={6}>
                                <CheckCircleIcon style={{ color: "green", fontSize: '68px'}} fontSize='68px' />
                                <Typography variant="h2" component="h2" mt={3} mb={3} gutterBottom>
                                    Félicitations !
                                </Typography>
                                <Typography variant="h4">
                                    Votre souscription a été réalisée avec succès.
                                </Typography>
                                <Typography variant="body1" mt={4} mb={1}>
                                    Un mail vous a été envoyé avec tous les détails de votre souscription.
                                </Typography>
                                <Typography variant="body1" mt={2} mb={6}>
                                    Nous vous contacterons sous peu pour la suite du processus.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </PageLayout>
    );
};

export default SuccessPageMaterial;
