import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "./components/IllustrationLayout";

// Image
import bgImage from "assets/images/login-bg.webp";
import partnersImage from "assets/images/partners.png";
import logoImage from "assets/images/logos/hakili.png";

function AuthenticationPageMaterial({identify}) {

  const [rememberMe, setRememberMe] = useState(false);
  const [startingDate, setStartingDate] = useState('2000-01-01');
  const [employeeId, setEmployeeId] = useState('');

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleStartingDateChange = (e) => {
    setStartingDate(e.target.value);
  };

  const handleEmployeeIdChange = (e) => {
    setEmployeeId(e.target.value);
  };


  return (
    <IllustrationLayout
      title="HAKILI TECH ACCESS"
      illustration={bgImage}
      flag={partnersImage}
      logo={logoImage}
      country="Fondation Didier Drogba"
    >
      <MDBox component="form" role="form">
        {/* <MDBox mb={2}>
          <MDInput type="text" label="Votre matricule" value={employeeId} onChange={handleEmployeeIdChange} fullWidth />
        </MDBox>
        <MDBox mb={2}>
          <MDInput type="date" label="Votre date de prise de service" value={startingDate} onChange={handleStartingDateChange} fullWidth />
        </MDBox> */}
        {/* <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Se rappeler de moi
          </MDTypography>
        </MDBox> */}
        <MDBox mt={8} mb={1}>
          <MDButton variant="gradient" color="info" size="large" fullWidth onClick={ ()=> identify({employeeId, startingDate}) }>
            Je m'enregistre
          </MDButton>
        </MDBox>
        
      </MDBox>
    </IllustrationLayout>
  );
}

export default AuthenticationPageMaterial;

