/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";

function Analytics() {
  const { sales, tasks } = reportsLineChartData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>

        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Souscriptions journalières"
                  description=""
                  date="Données des 7 derniers jours"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Souscriptions mensuelles"
                  description={""
                    // <>
                    //   (<strong>+15%</strong>) increase in today sales.
                    // </>
                  }
                  date="Données des 12 derniers mois"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={4}>
              <MDBox mb={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <MDBox mb={4}>
                    <ComplexStatisticsCard
                        icon="leaderboard"
                        title="Total des souscriptions des 7 derniers jours"
                        count="70"
                        percentage={{
                          color: "success",
                          amount: "+3%",
                          label: "par rapport a la semaine dernière",
                        }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                        color="success"
                        icon="store"
                        title="Total des souscriptions des 12 derniers mois"
                        count="10 000"
                        percentage={{
                          color: "success",
                          amount: "+1%",
                          label: "par rapport à l'an dernier",
                        }}
                    />
                  </MDBox>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        {/* <Grid container>
          <SalesByCountry />
        </Grid> */}

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
