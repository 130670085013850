/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";

// NewUser page components
import FormField from "layouts/pages/preinscription/components/FormField";
import { useCallback, useState } from "react";
import HouseCard from "../HouseCard";
import homeDecor1 from "assets/images/hp/hp_1.avif";
import homeDecor2 from "assets/images/lenovo/lenovo_14.avif";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import MDButton from "../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import { Modal } from "@mui/material";
import "primeicons/primeicons.css";
import { Carousel } from "primereact/carousel";

import computers from "./computers";
import './styles.css';


//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import { Dialog } from "primereact/dialog";

function LogementSouhaite({ formData, isRecap, gotoStep, step }) {
  const { formField, values, errors, touched } = formData;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogImages, setDialogImages] = useState([]);

  const [dialogDescription, setDialogDescription] = useState([]);

  const [selectedHomeState, setSelectedHomeState] = useState("");

  const [ss, setSS] = useState("");

  const clickedOnDetails = useCallback((computer) => {
    // values[formField.choosenModels.name] = value;
    setSelectedHomeState(computer.value);
    setDialogTitle(computer.title);
    setDialogImages(computer.images);
    setDialogDescription(computer.long_desc);
    setOpen(true);
  }, []);

  const onChooseAppartment = useCallback(() => {
    formField.choosenModels.values[selectedHomeState]++;
    setOpen(false);
    // gotoStep(step + 1);
  }, [selectedHomeState]);

  const imageTemplate = (image) => {
    return (
      <div>
        <div className="mb-3">
          <img src={image} style={{ height: "600px", maxHeight: "60vh" }} />
        </div>
      </div>
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 100; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleQuantityChange = useCallback((value, computer) => {
    formField.choosenModels.values[computer.value] = value;
    setSS(generateRandomString());
  }, []);

  return (
    <>
      <MDBox>
        <MDBox>
          <MDTypography variant="h4" textAlign={"center"} mb={3} mt={1}>
            {isRecap ? "" : "COCHEZ"} LES MODÈLES D'ORDINATEUR DE VOTRE CHOIX
            {isRecap ? (
              <MDButton
                variant="outlined"
                color="dark"
                iconOnly
                style={{ marginLeft: "8px" }}
                onClick={() => gotoStep(step)}
              >
                <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
              </MDButton>
            ) : (
              ""
            )}
          </MDTypography>
        </MDBox>

        <MDBox p={2}>
          <Grid container spacing={6}>
            {Object.values(computers).map((computer) =>
              !isRecap || ( formField.choosenModels.values[computer.value] && formField.choosenModels.values[computer.value] != 0) ? (
                <Grid key={computer.value} item xs={12} md={6} lg={6}>
                  <HouseCard
                    id={computer.value}
                    quantity={formField.choosenModels.values[computer.value]}
                    image={computer.image}
                    label=""
                    title={computer.title}
                    description={computer.short_desc}
                    apport={computer.apport}
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "Choisir ce modèle",
                    }}
                    currentValue={values[formField.choosenModels.name]}
                    value={computer.value}
                    onValueChanged={() =>
                      clickedOnDetails(computer)
                    }
                    hideButton={isRecap}
                    monthly={computer.monthly}
                    isRecap={isRecap}
                    handleQuantityChange={(value)=>{
                        handleQuantityChange(value, computer)
                    }}
                  />
                </Grid>
              ) : (
                ""
              )
            )}
          </Grid>
        </MDBox>
      </MDBox>

      <Dialog visible={open} onHide={handleClose}>
        <div style={{ width: "70vw" }}>
          <MDTypography id="modal-modal-title" variant="h3" component="h2">
            <div
              style={{
                display: "inline-block",
                width: "auto",
                marginRight: "64px",
              }}
            >
              Détails du modèle {dialogTitle}
            </div>
            <MDButton
              variant={"gradient"}
              size="small"
              color="info"
              onClick={onChooseAppartment}
            >
              Ajouter ce modèle
            </MDButton>
          </MDTypography>

          <div style={{ textAlign: "center", paddingTop: "24px" }}>
            <Carousel
              value={dialogImages}
              numVisible={1}
              responsiveOptions={responsiveOptions}
              itemTemplate={imageTemplate}
              circular
            />
          </div>

          <div
            style={{ padding: "16px 64px 32px", textAlign: "justify" }}
            dangerouslySetInnerHTML={{
              __html: dialogDescription,
            }}
          />

          <div style={{ textAlign: "center" }}>
            <MDButton
              variant={"gradient"}
              size="large"
              color="info"
              onClick={onChooseAppartment}
            >
              Ajouter ce modèle
            </MDButton>
          </div>
        </div>
      </Dialog>
    </>
  );
}

// typechecking props for Identification
LogementSouhaite.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default LogementSouhaite;
