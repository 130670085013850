/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/pages/preinscription/schemas/form";

const {
  formField: {
    firstName, lastName,
    sex, dateOfBirth, placeOfBirth,
    nationality, idType, idNumber,
    poBox, homePhone, mobilePhone,
    email, emergencyContact,
    spouseFirstName, spouseLastName,
    spouseSex, spouseDateOfBirth, spousePlaceOfBirth,
    spouseNationality, spouseIdType, spouseIdNumber,
    spouseMobilePhone, spouseEmail,
    maritalStatus, maritalRegime, childrenNumber
  },
} = checkout;

const USE_TEST_VALUES = true;
const initialValues = {};
 Object.entries(checkout.formField).forEach(
     ([k, v]) => { initialValues[v.name] = 
     USE_TEST_VALUES ? 
        v.testValue ?? v.defaultValue ?? "test " + v.name : 
        v.defaultValue;
      }
);

export default initialValues;
