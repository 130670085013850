import React from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Recap from "../Recap";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
    "& .MuiPaper-root": {
      width: "94%",
      maxWidth: "94%",
        height: "94vh",
    }
}));

function BootstrapDialogTitle({onClose, title}) {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
        {title}
    </DialogTitle>
  );
}

const ModalComponent = ({ open, handleClose, data }) => {
  return (
    <BootstrapDialog
      onClose={handleClose}
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} title="Details de souscription" />
      <DialogContent dividers>
          {/*<Recap formData={data} gotoStep={()=>{}} />*/}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalComponent;
