/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";

// NewUser page components
import {useState} from "react";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../../components/MDButton";

function Identification({formData, isRecap, gotoStep, step}) {

    const {formField, values, errors, touched, formFieldGrid, orderedFieldsBox} = formData;

    const orderedFieldsApplicantIdentity =
        [
            formField.lastName,formField.firstName,
            formField.sex, 
            formField.dateOfBirth,
            // formField.placeOfBirthCountry, 
            // formField.placeOfBirthTown,
            // formField.nationality, 
            // formField.idType, formField.idNumber,
            // formField.poBox, 
            // formField.homePhone, 
            formField.mobilePhone,
            formField.email
        ];


    function handleSelectedOption(itemValue, itemName) {
        setDefaultState(!defaultState);
        
    }

    const [defaultState, setDefaultState] = useState(true);
    
    return (
        <MDBox>
            <MDBox>
                <MDTypography variant="h4" textAlign={"center"} mb={3} mt={1}>
                    IDENTIFICATION
                    { isRecap ? <MDButton variant="outlined" color="dark" iconOnly style={{ marginLeft: "8px"}} onClick={ ()=> gotoStep(step) }>
                        <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                    </MDButton> : ""
                    }
                </MDTypography>
            </MDBox>

            <MDBox lineHeight={0}>
                <MDTypography variant="h5">Identité du demandeur</MDTypography>
            </MDBox>
            <MDBox mt={1.625}>
                <Grid container spacing={3}>
                    {orderedFieldsApplicantIdentity.map((item, i) => formFieldGrid(item.name, item, values, errors, handleSelectedOption, {isRecap}))}
                </Grid>
            </MDBox>

        </MDBox>
    );
}

// typechecking props for Identification
Identification.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Identification;
