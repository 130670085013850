/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";

// NewUser page components
import FormField from "layouts/pages/preinscription/components/FormField";


function Recap({ formData , gotoStep}) {
  const { formField, values, errors, touched, formFieldGrid } = formData;

  function handleSelectedOption(itemValue, itemName) {
      values[itemName] = itemValue;
  }

  return (
    <MDBox>

        <div style={{ height: 2, background: "rgba(204,204,204,0.4)"}}></div>

    </MDBox>
  );
}

// typechecking props for Identification
Recap.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Recap;
