/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import Footer from "examples/Footer";

// NewUser page components
import Identification from "./components/Identification";
import SituationSocioPro from "./components/SituationSocioPro";
import LogementSouhaite from "./components/LogementSouhaite";
import Recap from "./components/Recap";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/preinscription/schemas/validations";
import form from "layouts/pages/preinscription/schemas/form";
import initialValues from "layouts/pages/preinscription/schemas/initialValues";
import PageLayout from "../../../examples/LayoutContainers/PageLayout";
import MDTypography from "../../../components/MDTypography";
import { Dialog } from "primereact/dialog";
import Success from "./components/Success";
import Authentication from "./components/Authentication";
import computers from "./components/LogementSouhaite/computers";

import "./app.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function getSteps() {
  return [
    "Identification",
    "Situation Socio-professionnelle",
    // "Logement Actuel",
    "Ordinateur souhaité",
    // "Simulateur financier",
    "Confirmation",
  ];
}

function getStepContent(stepIndex, formData, gotoStep) {
  switch (stepIndex) {
    case 0:
      return <Identification formData={formData} isRecap={false} />;
    case 1:
      return <SituationSocioPro formData={formData} isRecap={false} />;
    case 2:
      return (
        <LogementSouhaite
          formData={formData}
          isRecap={false}
          gotoStep={gotoStep}
          step={2}
        />
      );
    case 3:
      return <Recap formData={formData} gotoStep={gotoStep} />;
    default:
      return null;
  }
}

function NewUser() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField, formFieldGrid, orderedFieldsBox } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [isSuccess, setIsSuccess] = useState(false);

  // Identify the subscriber based on is Employee ID and Date of Commencement of Duties.
  const [isIdentified, setIsIdentified] = useState(false);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  function handleBack() {
    setActiveStep(activeStep - 1);
    scrollToTop();
  }

  const submitForm = async (values, actions) => {
    sendDataToServer(values);
    // actions.setSubmitting(false);
    // actions.resetForm();
  };

  function sendDataToServer(data) {
    data.choosenModels = formField.choosenModels.values;
    setIsSuccess(true);
    // fetch("http://localhost:8001/api/subscriptions", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(
    //     {
    //       type: "FILLED_BY_USER",
    //       user: "anonymous",
    //       data: data,
    //     },
    //     null,
    //     2
    //   ),
    // })
    //   .then((response) => {
    //     if (response.ok) {
    //       setIsSuccess(true);
    //       console.log("Data appended successfully!");
    //     } else {
    //       setIsSuccess(true);
    //       console.error("Error appending data:", response.statusText);
    //     }
    //   })
    //   .catch((error) => {
    //     setIsSuccess(true);
    //     console.error(error);
    //   });
  }

  const handleSubmit = (values, actions) => {
    
    if (isLastStep) {
      // submitForm(values, actions);
      setOpen(true);
    } else {
      if (activeStep == 2) {
        const total = Object.values(computers)
            .map(
              (computer) => formField.choosenModels.values[computer.value] ?? 0
            )
            .reduce((prev, curr) => prev + curr, 0);
        console.log(total);
        if (total === 0) {
          toast.error("Veuillez choisir au moins un ordinateur");
          console.log("Veuillez choisir au moins un ordinateur");
          return;
        }
      }
      setActiveStep((prev) => prev + 1);
      
      scrollToTop();
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function identify(data) {
    setIsIdentified(true);
  }

  function goForStep(step) {
    setActiveStep(step);
    scrollToTop();
  }

  function scrollToTop() {
    const element = document.getElementById("step-content-container");
    element.scrollTop = 0;
  }

  function formatAmount(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return !isIdentified ? (
    <Authentication identify={identify} />
  ) : isSuccess ? (
    <Success />
  ) : (
    <PageLayout>
      <ToastContainer />
      <Grid item xs={12} lg={8} pt={4} pb={3}>
        <MDBox mb={1} textAlign="center">
          <MDTypography variant="h3" fontWeight="bold">
            Pré-enregistrement - Hakili Tech Access
          </MDTypography>
        </MDBox>
      </Grid>
      <MDBox py={5} mb={2} height="calc(100vh - 150px)" overflow="hidden">
        <Grid
          container
          justifyContent="center"
          height={"100%"}
          alignItems="center"
          sx={{ height: "100%", mt: 0 }}
        >
          <Grid item xs={12} lg={8} height={"100%"}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, form }) => (
                <>
                  <Form id={formId} autoComplete="off" height="100%">
                    <Card sx={{ height: "100%" }}>
                      <MDBox mx={2} mt={-3}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </MDBox>

                      <MDBox
                        id="step-content-container"
                        p={3}
                        height={"calc(100vh - 270px)"}
                        overflow="scroll"
                      >
                        <MDBox>
                          {getStepContent(
                            activeStep,
                            {
                              values,
                              touched,
                              formField,
                              errors,
                              formFieldGrid,
                              orderedFieldsBox,
                            },
                            goForStep
                          )}
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {activeStep === 0 ? (
                              <MDBox />
                            ) : (
                              <MDButton
                                variant="gradient"
                                color="light"
                                onClick={handleBack}
                              >
                                Précédent
                              </MDButton>
                            )}
                            <MDButton
                              // disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color={isLastStep ? "success" : "dark"}
                            >
                              {isLastStep
                                ? "Valider mon enregistrement"
                                : "Suivant"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                  <Dialog visible={open} onHide={handleClose}>
                    <div style={{ width: "500px" }}>
                      <MDTypography
                        id="modal-modal-title"
                        variant="h4"
                        component="h4"
                        mb={3}
                      >
                        <div
                          style={{
                            display: "inline-block",
                            width: "auto",
                            marginRight: "64px",
                          }}
                        >
                          Validation
                        </div>
                      </MDTypography>

                      <div>
                        En continuant vous certifier que toutes les informations
                        entrées sont correctes.
                      </div>

                      <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                        Vous serez débité de{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {formatAmount(
                            Object.values(computers)
                              .map(
                                (computer) =>
                                  (formField.choosenModels.values[
                                    computer.value
                                  ] ?? 0) *
                                  parseInt(computer.monthly.replace(" ", ""))
                              )
                              .reduce((prev, curr) => prev + curr)
                          )}{" "}
                          F CFA
                        </span>{" "}
                        par mois.
                      </div>

                      <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                        Vous devrez verser un apport initial de{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {formatAmount(
                            Object.values(computers)
                              .map(
                                (computer) =>
                                  (formField.choosenModels.values[
                                    computer.value
                                  ] ?? 0) * parseInt(computer.apport.replace(" ", ""))
                              )
                              .reduce((prev, curr) => prev + curr)
                          )}{" "}
                          F CFA
                        </span>{" "}
                        .
                      </div>

                      <div style={{ textAlign: "center", marginTop: "32px" }}>
                        <MDButton
                          variant={"gradient"}
                          // size="large"
                          color="success"
                          onClick={() => {
                            submitForm(values);
                          }}
                        >
                          Je réserve mes ordinateurs
                        </MDButton>
                      </div>
                    </div>
                  </Dialog>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </PageLayout>
  );
}

export default NewUser;
