
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function IllustrationLayout({ header, title, illustration, country, flag, logo, children }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <PageLayout background="white">
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) =>
            darkMode ? background.default : white.main, 
        }}
      >
        <Grid item xs={12} lg={8}>
          <MDBox
            display={{ xs: "none", lg: "flex" }}
            width="100%"
            height="100vh"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${illustration})`, backgroundSize: 'cover', margin: "0 !important"  }}
          />
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={4} sx={{ mx: "auto" }}>
          <MDBox display="flex" flexDirection="column" height="100vh">
            <MDBox py={3} pb={0} px={3} textAlign="center">
              {!header ? (
                <>
                  <MDBox mb={6} mt={6} textAlign="center">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                      <img src={logo} alt="description" style={{ width : "300px", display: "inline-block" }}/>
                    </div>
                  </MDBox>
                  <MDBox mb={5} textAlign="center">
                    <img src={flag} alt="description" style={{ width : "100%", display: "inline-block", marginBottom: "24px" }}/>
                    <MDTypography variant="h4" fontWeight="bold">
                      <div dangerouslySetInnerHTML={{ __html: title}} />
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="body1" color="text" fontWeight="bold">
                    Pré-enregistrez vous pour <br/> réserver vos ordinateurs
                  </MDTypography>
                </>
              ) : (
                header
              )}
            </MDBox>
            <MDBox p={4} pt={1} style={{ maxWidth: '90%' , width: '400px' }} mx={'auto'}>{children}</MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: "",
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.string,
};

export default IllustrationLayout;
